import styled from 'styled-components'
import { breakPoints, fonts, colors } from '@constants/styles'
import Recaptcha from 'react-recaptcha'

export const OrderSearchWrapper = styled.div`
  width: 100%;
`

export const RecaptchaWrapper = styled(Recaptcha)`
  width: 100%;
`

export const OrderStatusWrapper = styled.section`
  padding: 1rem;
`

export const Title = styled.h1`
  color: ${colors.darkGrey};
  line-height: 1;
  font-size: ${fonts.txtLarge};
  font-weight: 400;
  padding: 0;
  text-align: left;
  margin-bottom: 1rem;
  text-transform: uppercase;

  @media only screen and (min-width: ${breakPoints['large-min']}) {
    font-size: ${fonts.txtXlarge};
  }
`

export const Message = styled.p`
  margin-bottom: 1rem;
`

export const Loader = styled.img`
  height: 1rem;
`

export const Error = styled.span`
  font-size: ${fonts.txtXsmall};
  color: ${colors.red};
`

// * * * * * Search Form * * * * *

export const SearchFormWrapper = styled.form`
  display: flex;
`

export const FindOrderButton = styled.button`
  &&& {
    height: 2.5rem;
    width: 9rem;

    @media only screen and (min-width: ${breakPoints['large-min']}) {
      margin-left: 0;
    }
  }
`

export const SearchInput = styled.input`
  &&& {
    width: 100%;
    max-width: 500px;
    border-color: ${({ error, phrase }) => error && error.includes(phrase) && colors.red};
  }
`

export const SearchInputLabel = styled.label`
  display: flex;
  flex-direction: column;
  span {
    color: ${colors.red};
  }
`

// * * * * * Order Search Legend * * * * *

export const LegendWrapper = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 160px;
  justify-content: space-between;
`

export const Legend = styled.section`
  display: flex;
  justify-content: flex-start;
  font-size: ${fonts.large};

  span {
    color: ${colors.red};
  }
`

export const ReasonsForStatusCheck = styled.ul`
  margin: 0 0 10px;
  li {
    list-style-type: disc;
    margin-left: 20px;
  }
`

// * * * * * Order Search Help Text * * * * *

export const HelpTextWrapper = styled.div`
  @media only screen and (min-width: ${breakPoints['large-min']}) {
    padding: 3rem;
  }
`

export const BackgroundBox = styled.div`
  padding: 1rem 0;
  border: none;
  border-top: 1px solid ${colors.grey};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  h2 {
    color: ${colors.primary};
    font-weight: 600;
  }
  a {
    color: ${colors.primary};
    text-decoration: underline;
    font-weight: 600;
    &:hover {
      color: ${colors.grey};
    }
  }
  @media only screen and (min-width: ${breakPoints.medium}) {
    border: none;
    border-left: 1px solid ${colors.grey};
  }
`
