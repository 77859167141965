import React, { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { navigate } from 'gatsby'
import RTGLink from '@shared/link'
import { setOrderList } from '@redux/modules/orderStatus'
import { validatePhone, validateZip } from '@helpers/string-helper'
import { announce } from '@helpers/aria-announce'
import { getOrdersHistory } from '@services/order-status'
import { orderStatusEvent } from '@helpers/google-tag-manager'
import OrderSearchLegend from './order-search-legend'
import SearchForm from './search-form'
import { OrderSearchWrapper, OrderStatusWrapper, Title, Message, HelpTextWrapper, BackgroundBox } from './styles'

const componentTitle = 'order status'

const componentMessage =
  'Enter your order number and phone number below to pull up your order history for the last 180 days.'

const OrderSearch = () => {
  const dispatch = useDispatch()
  const orderListRef = useRef(null)
  const [orderId, setOrderId] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [err, setErr] = useState(null)
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [zipCode, setZipCode] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const validPhone = validatePhone(phoneNumber)
      const validZip = validateZip(zipCode)
      if (query === '') {
        setLoading(false)
      } else if (orderId === '') {
        setErr('Order ID is required')
        setQuery('')
      } else if (validPhone && validZip) {
        const orderHistory = await getOrdersHistory(query)
        if (orderHistory.error) {
          announce('We are unable to retrieve your order, please try again later.')
          setErr(`We are unable to retrieve your order, please try again later.`)
          setQuery('')
          dispatch(setOrderList({ orderList: [] }))
        } else {
          dispatch(setOrderList(orderHistory?.rtgOrders || []))
          setErr(null)
          const [analyticsOrder = {}] = orderHistory?.rtgOrders?.filter(order => order?.OrderNumber === orderId)
          orderStatusEvent('findOrderSuccess', analyticsOrder)
          // TODO: navigation need to add code to track ga data
          navigate('/order/list')
        }
      } else if (!validZip) {
        setLoading(false)
        setErr('Zip code is not valid')
        setQuery('')
      } else {
        setLoading(false)
        setErr('Phone number is not valid')
        setQuery('')
      }

      setLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return (
    <OrderSearchWrapper className="grid-x" ref={orderListRef}>
      <OrderStatusWrapper className="cell small-12 medium-8 large-6">
        <Title>{componentTitle}</Title>
        <Message>{componentMessage}</Message>
        <OrderSearchLegend />
        <SearchForm
          setQuery={setQuery}
          setOrderId={setOrderId}
          orderId={orderId}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          zipCode={zipCode}
          setZipCode={setZipCode}
          error={err}
          loading={loading}
          orderListRef={orderListRef}
        />
      </OrderStatusWrapper>
      <HelpTextWrapper className="cell small-12 medium-4 large-6">
        <BackgroundBox className="background-box">
          <h2>Need Help?</h2>
          <p>
            <RTGLink
              data={{
                target: '_blank',
                url: 'mailto:servicio@roomstogo.pr',
                category: 'order search',
                action: 'roomstogo csp click',
                label: 'Request Service',
              }}
            >
              Request Service
            </RTGLink>
          </p>
          <br />
          <p>
            Customer Care Center:
            <br />
            <a href="tel:(787) 999-9777"> (787) 999-9777</a>
            <br />
            <a href="tel:(787) 251-1700"> (787) 251-1700</a>
          </p>
        </BackgroundBox>
      </HelpTextWrapper>
    </OrderSearchWrapper>
  )
}

export default OrderSearch
