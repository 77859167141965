import React, { useState } from 'react'
import PropTypes from 'prop-types'
import loaderLight from '@images/loader-light.svg'
import { verifyRecaptchaResponse } from '@components/gift-cards/balance/balance-helper'
import useHasWindow from '@helpers/hooks/useWindow'
import { getRegionZone } from '@helpers/geo-location'
import {
  FindOrderButton,
  Error,
  Loader,
  SearchFormWrapper,
  SearchInput,
  SearchInputLabel,
  RecaptchaWrapper,
} from './styles'

/* for silent recaptcha */
const SITE_KEY = '6LfHmagaAAAAALbJQjTq_LRqpkzQlNVjhQED-tx8'

const SearchForm = ({
  setQuery,
  orderListRef,
  setOrderId,
  setPhoneNumber,
  zipCode,
  setZipCode,
  error,
  orderId,
  phoneNumber,
  loading,
}) => {
  /* state for recaptcha */
  const [verifiedRecaptcha, setVerifiedRecaptcha] = useState(false)
  const isWindow = useHasWindow()
  const regionInPR = getRegionZone().region === 'PR'

  /* for silent recaptcha */
  const verifyCallback = async token => {
    const verified = await verifyRecaptchaResponse(token)
    setVerifiedRecaptcha(verified?.success)
  }

  return (
    <SearchFormWrapper className="grid-x cell small-12" ref={orderListRef}>
      {error && <Error>{error}</Error>}
      <SearchInputLabel htmlFor="order_status_number" className="cell small-12">
        <div>
          <span>*</span>Order Number
        </div>
        <SearchInput
          className="cell small-12"
          type="text"
          name="order_number"
          maxLength={20}
          id="order_status_number"
          onChange={e => setOrderId(e.target.value)}
          error="order"
          phrase="phone"
        />
      </SearchInputLabel>
      <SearchInputLabel htmlFor="tel" className="cell small-12">
        <div>
          <span>*</span>Phone Number
        </div>
        <SearchInput
          className="cell small-12"
          type="tel"
          name="order_phone_number"
          id="tel"
          autoComplete="tel"
          onChange={e => setPhoneNumber(e.target.value)}
          error={error}
          phrase="phone"
        />
      </SearchInputLabel>
      <SearchInputLabel htmlFor="zipcode" className="cell small-12">
        <div>
          <span>*</span>Delivery Zip Code
        </div>
        <SearchInput
          className="cell small-12"
          name="order_zip"
          type="text"
          maxLength={5}
          id="zipcode"
          autoComplete="postal-code"
          onChange={e => setZipCode(e.target.value)}
          error={error}
          phrase="Zip"
        />
      </SearchInputLabel>
      {isWindow && !regionInPR && (
        <RecaptchaWrapper sitekey={SITE_KEY} render="explicit" verifyCallback={verifyCallback} />
      )}
      <FindOrderButton
        disabled={!regionInPR && !verifiedRecaptcha}
        type="button"
        className="primary"
        onClick={() =>
          setQuery(`?orderId=${orderId}&phoneNumber=${phoneNumber.replace(/[^0-9]/g, '')}&zipcode=${zipCode}`)
        }
      >
        {!loading ? 'Find Order' : <Loader alt="loading matching orders" src={loaderLight} />}
      </FindOrderButton>
    </SearchFormWrapper>
  )
}

SearchForm.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.any,
  orderId: PropTypes.any,
  orderListRef: PropTypes.any,
  phoneNumber: PropTypes.any,
  setOrderId: PropTypes.func,
  setPhoneNumber: PropTypes.func,
  setQuery: PropTypes.func,
  zipCode: PropTypes.any,
  setZipCode: PropTypes.func,
}

export default SearchForm
