import React from 'react'
import styled from 'styled-components'
import OrderSearch from './order-search'

const OrderStatusWrapper = styled.div`
  margin-top: 2rem;
  .active-orders-list h2 {
    margin: 1rem 0;
  }
`

const OrderStatus = () => (
  <OrderStatusWrapper>
    <div className="order-status">
      <div className="cell small-12 card grid-x">
        <OrderSearch />
      </div>
    </div>
  </OrderStatusWrapper>
)

OrderStatus.displayName = 'OrderStatus'

export default OrderStatus
