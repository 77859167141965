const axios = require('axios')

export const getOrderList = body =>
  axios.post(`${process.env.GATSBY_ORDER_MANAGEMENT_URL}/getRelatedOrders`, body).then(response => response.data)

export const getOrdersHistory = query =>
  axios
    .get(`${process.env.GATSBY_ORDER_MANAGEMENT_URL}/ordersHistory${query}`)
    .then(response => response.data)
    .catch(error => {
      if (error?.response?.data?.error?.message) {
        return { error: error.response.data.error.message }
      }
      return error
    })

export const getOrderDetails = (orderId, zip) =>
  axios
    .get(`${process.env.GATSBY_ORDER_MANAGEMENT_URL}/rtgOrder?orderId=${orderId}&zipcode=${zip}`)
    .then(response => response.data)

export const setPayOnline = body =>
  axios.post(`${process.env.GATSBY_ORDER_MANAGEMENT_URL}/payonline`, body).then(response => response.data)

export const getOrderHistoryByKey = key =>
  axios
    .get(`${process.env.GATSBY_ORDER_MANAGEMENT_URL}/ordersHistoryByKey?orderKey=${key}`)
    .then(response => response.data)

export const getPremiumDeliveryCharge = (orderId, zip, phoneNumber) =>
  axios
    .get(
      `${process.env.GATSBY_ORDER_MANAGEMENT_URL}/premiumDelivery?orderId=${orderId}&zipcode=${zip}&phoneNumber=${phoneNumber}`,
    )
    .then(response => response.data)

export const balanceInquiry = body =>
  axios
    .post(`${process.env.GATSBY_ORDER_MANAGEMENT_URL}/synchrony/balanceInquiry`, body)
    .then(response => response.data)

export const rescheduleDelivery = body =>
  axios.post(`${process.env.GATSBY_DELIVERY_SERVICE_URL}/reschedule`, body).then(response => response.data)
